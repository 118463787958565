import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My CLI apps:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/folder-to-youtube-playlist"
        }}>{`music folder to YouTube playlist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/dropbox-folder-uploader"
        }}>{`dropbox folder uploader`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/npm2neo4j"
        }}>{`NPM module visualization in Neo4j`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/rogauracore-gui"
        }}>{`GUI for a linux keyboard lighting program`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/sincode"
        }}>{`simple cypher cracker`}</a>{` (like the A = C code wheel decoders)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/github-auto-pull"
        }}>{`Automatic Github repo updater`}</a>{` (CI on a budget)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/twitch-chat-panel"
        }}>{`Twitch chat electron panel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/calcmedic-scraper"
        }}>{`website scraper for a calc course`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/chat"
        }}>{`simple TCP chat client/server`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/mc-plus-plus"
        }}>{`Minecraft Spigot server wrapper & UI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/cli-apps/writenull"
        }}>{`write random data to a file program`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      